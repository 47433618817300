<template>
  <el-dialog v-dialogDrag :title="title" @open.once="initEditor" :visible.sync="visible" width="1000px">
    <el-form label-width="100px" class="form" ref="form" :model="form" :rules="rules">
      <el-form-item label="标题" prop="title">
        <el-input v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select v-model="form.type">
          <el-option v-for="item in typeList" :key="item.id" :value="item.value" :label="item.dictLabel"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <div id="editor" class="editor"></div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:show',false)">取 消</el-button>
      <el-button size="small" type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import E from "wangeditor";
import { wangEditorUpload } from "@/utils/oss";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {},
      loading: false,
      editor: null,
      rules: {
        title: [{ required: true, message: "标题必填" }],
        type: [{ required: true, message: "类型必填",trigger:['change','blur'] }],
        content: [{ required: true, message: "内容必填" }],
      },
      typeList: [],
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
    title() {
      var title = "新增服务协议";
      if (this.form.id) {
        title = "修改服务协议";
      }
      return title;
    },
  },
  watch: {
    row: {
      handler(val) {
        this.initForm(val);
      },
      deep: true,
    },
  },
  created() {
    this.getType();
    this.initForm(this.row);
  },
  mounted() {},
  methods: {
    /* 获取协议字典类型 */
    getType() {
      this.$get("/platform-admin/dict/data/page", {
        page: 1,
        limit: 99,
        dictTypeId: "1407985006663565313",
      }).then((res) => {
        if (res && res.code === 0) {

          this.typeList = res.data.list;
          this.typeList.map(item=>{
            this.$set(item,'value',Number(item.dictValue))
          })
        }
      });
    },
    resetForm() {
      this.form = {
        title: null,
        content: null,
      };
      if (this.editor) this.editor.txt.clear();
      // 判断是否拿到表单元素，重置表单
      if (this.$refs && this.$refs.form) {
        this.$refs.form.resetFields();
        this.$forceUpdate();
      }
    },
    initForm(val) {
      // 初始化form数据
      console.log(val);
      this.resetForm();
      console.log(val);
      if (val.id) {
        this.form = { ...val };
        if (this.editor && this.form.content) {
          this.editor.txt.html(this.form.content);
        }
      }
    },
    initEditor() {
      this.$nextTick(() => {
        console.log(document.getElementById("editor"));
        this.editor = new E("#editor");
        this.editor.config.customUploadImg = wangEditorUpload;
        this.editor.create();
        if (this.form.content) {
          this.editor.txt.html(this.form.content);
        }
        console.log(this.editor.config);
      });
    },
    submit() {
      if (this.editor.txt.html()) {
        this.form.content = this.editor.txt.html();
      } else {
        this.form.content = "";
      }
      
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          var data = { ...this.form };

          if (data.id) {
            var res = await this.$put("/platform-config/serviceprotocol", data);
          } else {
            var res = await this.$post(
              "/platform-config/serviceprotocol",
              data
            );
          }
          this.loading = false;
          if (res && res.code === 0) {
            var str = data.id ? "修改成功" : "添加成功";
            var ev = data.id ? "edit" : "add";
            this.$message.success(str);
            this.$emit(ev);
            this.$emit("update:show", false);
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.editor {
  width: 100%;
  height: 400px;
}
.form {
  padding: 20px;
}
</style>